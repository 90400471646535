import { render, staticRenderFns } from "./dataReporting.vue?vue&type=template&id=29a81729&scoped=true"
import script from "./dataReporting.vue?vue&type=script&lang=js"
export * from "./dataReporting.vue?vue&type=script&lang=js"
import style0 from "./dataReporting.vue?vue&type=style&index=0&id=29a81729&prod&lang=scss"
import style1 from "./dataReporting.vue?vue&type=style&index=1&id=29a81729&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a81729",
  null
  
)

export default component.exports